define("discourse/plugins/discourse-journal/discourse/connectors/after-topic-footer-main-buttons/entry-button-wrapper", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      createEntry() {
        const controller = (0, _application.getOwner)(this).lookup("controller:topic");
        controller.send("replyToPost");
      }
    }
  };
});