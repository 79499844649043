define("discourse/plugins/discourse-journal/discourse/connectors/category-custom-settings/enable-journal", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      updateSortOrder() {
        this.set("updatingSortOrder", true);
        (0, _ajax.ajax)("/journal/update-sort-order", {
          type: "POST",
          data: {
            category_id: this.category.id
          }
        }).then(result => {
          let syncResultIcon = result.success ? "check" : "times";
          this.setProperties({
            updatingSortOrder: false,
            syncResultIcon
          });
        }).catch(() => {
          this.setProperties({
            syncResultIcon: "times",
            updatingSortOrder: false
          });
        }).finally(() => {
          setTimeout(() => {
            this.set("syncResultIcon", null);
          }, 6000);
        });
      }
    }
  };
});