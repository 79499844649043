define("discourse/plugins/discourse-journal/discourse/initializers/journal-composer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/models/composer", "@ember/object", "@ember/object/computed", "I18n"], function (_exports, _pluginApi, _decorators, _composer, _object, _computed, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-journal";
  var _default = _exports.default = {
    name: "journal-composer",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.journal_enabled) {
        return;
      }
      function getJournalComposerKey(action, composerModel) {
        let key;
        let post = composerModel.post;
        if (action === _composer.CREATE_TOPIC) {
          key = "create_journal";
        } else if (action === _composer.REPLY && post) {
          key = post.comment ? "reply_to_comment" : "create_comment";
        } else if (action === _composer.EDIT && post) {
          key = post.comment ? "edit_comment" : "edit_entry";
        } else {
          key = "create_entry";
        }
        return key;
      }
      function getJournalComposerText(type) {
        let icon = "reply";
        if (type === "create_comment") {
          icon = "comment";
        } else if (type === "create_journal") {
          icon = "plus";
        } else if (["edit_entry", "edit_comment"].includes(type)) {
          icon = "pencil-alt";
        }
        return {
          icon,
          name: `composer.composer_actions.${type}.name`,
          description: `composer.composer_actions.${type}.description`
        };
      }
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        api.modifyClass("controller:composer", dt7948.p({
          pluginId: PLUGIN_ID,
          open(opts) {
            if (opts.topic && opts.topic.journal && opts.quote && !opts.post) {
              opts.post = opts.topic.postStream.posts[0];
            }
            return this._super(opts);
          },
          isJournal(category) {
            return category && category.journal;
          },
          journalComposerText(action) {
            let key = getJournalComposerKey(action, this.model);
            return getJournalComposerText(key);
          },
          saveLabel(modelAction, isWhispering, editConflict, isJournal, journalLabel) {
            if (isJournal) {
              return journalLabel;
            } else {
              return this._super(...arguments);
            }
          },
          saveIcon(modelAction, isWhispering, isJournal, journalIcon) {
            if (isJournal) {
              return journalIcon;
            } else {
              return this._super(...arguments);
            }
          }
        }, [["method", "isJournal", [(0, _decorators.default)("model.category")]], ["method", "journalComposerText", [(0, _decorators.default)("model.action", "model.post")]], ["method", "saveLabel", [(0, _decorators.default)("model.action", "isWhispering", "model.editConflict", "isJournal", "journalComposerText.name")]], ["method", "saveIcon", [(0, _decorators.default)("model.action", "isWhispering", "isJournal", "journalComposerText.icon")]]]));
        api.modifyClass("component:composer-action-title", dt7948.p({
          pluginId: PLUGIN_ID,
          actionTitle(opts, action, category) {
            let key = getJournalComposerKey(action, this.model);
            let text = getJournalComposerText(key);
            if (category && category.journal && text) {
              return _I18n.default.t(text.name);
            } else {
              return this._super(...arguments);
            }
          }
        }, [["method", "actionTitle", [(0, _decorators.default)("options", "action", "model.category")]]]));
        api.modifyClass("component:composer-actions", {
          pluginId: PLUGIN_ID,
          didReceiveAttrs() {
            const composer = this.get("composerModel");
            if (composer) {
              this.set("postSnapshot", composer.post);
            }
            this._super(...arguments);
          },
          commenting: (0, _computed.alias)("postSnapshot.journal"),
          commentKey: (0, _object.computed)("commenting", function () {
            return getJournalComposerKey(this.action, this.composerModel);
          }),
          iconForComposerAction: (0, _object.computed)("action", "commenting", function () {
            if (this.commenting) {
              return getJournalComposerText(this.commentKey).icon;
            } else {
              return this._super(...arguments);
            }
          }),
          content: (0, _object.computed)("seq", "commenting", function () {
            if (this.commenting) {
              const text = getJournalComposerText(this.commentKey);
              return [{
                id: "reply_to_post",
                icon: text.icon,
                name: _I18n.default.t(text.name),
                description: _I18n.default.t(text.description)
              }];
            } else {
              return this._super(...arguments);
            }
          })
        });
      });
    }
  };
});